<template>
  <div id="game">

    <div id="headerSection">

        <div id="logo">
            <img src="/img/borgle-logo.svg" alt="">
        </div>

      <div id="buttonRow">

        <button
        type="button"
        id="genBoard"
        class="button"
        v-bind:disabled="generateDisabled"
        v-bind:class="{ primary: this.phase === 4, secondary: this.phase != 4 }"
        @click="shareBoard()">
            Generate and Copy Board Link
        </button>

        <button
        type="button"
        id="clearScore"
        class="button tertiary"
        @click="clearScore()">
            Clear Total Score
        </button>

        <button
        type="button"
        id="findAllWords"
        class="button tertiary"
        @click="findAllWords()">
            Find all words
        </button>

      </div>

    </div>

    <div id="mainSection">

      <div id="boardSection">

          <div id="board" ref="board">

            <div
            v-for="(row,x) in board"
            :key="x+row"
            class="row">

                <Tile
                v-for="(tile, i) in row"
                :key="i+tile"
                :letter="tile"
                :debug="false"
                :tileIndex="(x*5)+i"
                :id="`row${x+1}tile${i+1}`"
                :hideLetters="hideLetters"/>
            </div>

          </div>
      </div>

      <div id="sidePanel">

        <div id="gameInfo">

          <div id="timerSection" class="infoBadge">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 6V12L16 14"  stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <div id="countdown">

              <h1 v-if="phase === 2">
                  <!-- <vue-countdown :time="10000" @end="roundEnd" :transform="transformSlotProps" v-slot="{ minutes, seconds }">{{ minutes }}:{{seconds}}</vue-countdown> -->
                  <vue-countdown :time="120000" @end="roundEnd" v-slot="{ totalSeconds }">{{totalSeconds}}</vue-countdown>
              </h1>

              <h1 v-else>120</h1>

            </div>
          </div>

          <div id="scoreSection" class="infoBadge">

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 15C15.866 15 19 11.866 19 8C19 4.13401 15.866 1 12 1C8.13401 1 5 4.13401 5 8C5 11.866 8.13401 15 12 15Z"  stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <h1>{{totalScore}}</h1>

            <div id="roundScore">
              <h2><span v-if="this.roundScore >= 0">+</span>{{roundScore}}</h2>
              <p>this round</p>
            </div>

          </div>

        </div>

        <div id="inputSection">

          <button
          v-if="((this.phase === 1) || (this.phase === 4))"
          type="button"
          id="startTimer"
          class="button primary"
          v-bind:disabled="this.phase != 1"
          @click="roundStart">
              Start Round
          </button>

          <button
          v-else
          type="button"
          id="saveScore"
          class="button primary"
          v-bind:disabled="this.phase != 3"
          v-bind:class="{ hidden: this.phase != 3 }"
          @click="saveScore()">
              Finish Round
          </button>

          <input
          type="text"
          id="wordInput"
          ref="wordInput"
          v-bind:disabled="this.phase != 2"
          v-bind:class="{ hidden: this.phase != 2 }">

        </div>

        <div id="submittedWords" v-bind:class="{'lockedIn':saveDisabled}">
          <SubmittedWord
          v-for="(wordObj, i) in submittedWords"
          :key="i+wordObj.word"
          :word="wordObj.word"
          :points="wordObj.points"
          :inDictionary="wordObj.inDictionary"
          :onBoard="wordObj.onBoard"
          :enabled="wordObj.enabled"
          :roundActive="phase === 2"
          :punish="settings.punishMode"
          @click.native="toggleWord(i)"/>
        </div>

      </div>

    </div>

    <div id="settings">
        <div class="setting-group" @click="toggleSetting('punishMode')">
            <input type="checkbox" id="punishMode" v-model="settings.punishMode">
            <label for="checkbox">Punish mode?</label>
        </div>
    </div>

  </div>
</template>

<script>

import Tile from './Tile.vue'
import SubmittedWord from './SubmittedWord.vue'
import wordlist from '../assets/wordlist.json'
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'Game',
  components: { Tile, SubmittedWord, VueCountdown },
  data () {
    return {
      totalFrequency: 0,
      phase: 1,
      startDisabled: false,
      saveDisabled: true,
      generateDisabled: false,
      allDice: ['aaafrs', 'aaeeee', 'aafirs', 'adennn', 'aeeeem', 'aeegmu', 'aegmnn', 'afirsy', 'bbjkxz', 'ccenst', 'eiilst', 'ceipst', 'ddhnot', 'dhhlor', 'dhhnow', 'dhlnor', 'eiiitt', 'eilpst', 'emottt', 'ensssu', 'gorrvw', 'iprsyy', 'nootuw', 'ooottu', '1234567'],
      board: [],
      rawBoardLetters: [],
      specialTile: {
        code: null,
        value: null
      },
      wordlist: wordlist,
      submittedWords: [],
      roundScore: 0,
      totalScore: 0,
      hideLetters: true,
      settings: {
        punishMode: false
      }
    }
  },
  created () {

  },
  methods: {
    transformSlotProps (props) {
      const formattedProps = {}

      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = value < 10 ? `0${value}` : String(value)
      })

      return formattedProps
    },

    roundStart: function () {
      setTimeout(() => {
        this.$refs.wordInput.focus()
      }, 10)

      this.roundScore = 0
      this.startDisabled = true
      this.generateDisabled = true
      this.phase = 2
      this.hideLetters = false
      this.submittedWords = []
    },

    roundEnd: function () {
      this.phase = 3
      this.saveDisabled = false

      // Alphabetize submitted words
      function sortByKey (array, key) {
        return array.sort(function (a, b) {
          var x = a[key]; var y = b[key]
          return ((x < y) ? -1 : ((x > y) ? 1 : 0))
        })
      }

      sortByKey(this.submittedWords, 'word')

      this.submittedWords.forEach(word => {
        console.log(word)
        if ((word.inDictionary) && (word.onBoard)) { // Word is deemed valid
          this.roundScore += word.points
        } else { // Word is deemed invalid {
          if (this.settings.punishMode) { this.roundScore -= word.points } // Remove points if punish mode is active
          word.enabled = false
        }
      })
    },

    saveScore () {
      this.phase = 4
      this.submittedWords = []
      this.$refs.wordInput.value = ''
      this.saveDisabled = true
      this.generateDisabled = false
      this.totalScore += this.roundScore
      localStorage.totalScore = this.totalScore
      this.roundScore = 0
    },

    rescoreRound () {
      this.roundScore = 0

      this.submittedWords.forEach(word => {
        if ((word.inDictionary) && (word.onBoard)) {
          if (word.enabled) { this.roundScore += word.points }
        } else {
          if (this.settings.punishMode) { this.roundScore -= word.points } // Remove points if punish mode is active
        }
      })
    },

    toggleWord (i) {
      if ((this.submittedWords[i].inDictionary) && (this.submittedWords[i].onBoard)) {
        this.submittedWords[i].enabled = !this.submittedWords[i].enabled
        this.rescoreRound()
      }
    },

    shuffle (array) {
      let currentIndex = array.length; let randomIndex

      // While there remain elements to shuffle...
      while (currentIndex !== 0) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]]
      }

      return array
    },

    generateBoard () {
      this.rawBoardLetters = []
      const shuffledDice = this.shuffle(this.allDice)
      console.log(shuffledDice)

      const tempBoard = [[], [], [], [], []]

      // let boardRows
      shuffledDice.forEach((die, i) => {
        const letter = this.shuffle([...die])[0]

        if (i <= 4) {
          tempBoard[0].push(letter)
        } else if (i <= 9) {
          tempBoard[1].push(letter)
        } else if (i <= 14) {
          tempBoard[2].push(letter)
        } else if (i <= 19) {
          tempBoard[3].push(letter)
        } else {
          tempBoard[4].push(letter)
        }

        this.rawBoardLetters.push(letter)
      })

      this.board = tempBoard
      this.updateSpecialTile()
      console.log(this.board)

      this.phase = 1
    },

    updateSpecialTile () {
      this.specialTile.code = this.rawBoardLetters.join().match(/\d+/)[0]

      switch (this.specialTile.code) {
        case '1': this.specialTile.value = 'qu'; break
        case '2': this.specialTile.value = 'in'; break
        case '3': this.specialTile.value = 'th'; break
        case '4': this.specialTile.value = 'er'; break
        case '5': this.specialTile.value = 'he'; break
        case '6': this.specialTile.value = 'an'; break
        case '7': this.specialTile.value = '_'; break
      }

      console.log(this.specialTile)
    },

    shareBoard () {
      this.phase = 1
      this.generateBoard()
      this.hideLetters = true
      this.startDisabled = false
      const x = JSON.stringify(this.board)
      const y = btoa(x)
      navigator.clipboard.writeText(`https://classic.borgle.xyz/?board=${y}`)
    },

    loadBoard (str) {
      const x = atob(str)
      const y = JSON.parse(x)
      this.board = y
      this.generateDisabled = true

      this.rawBoardLetters = []
      this.board.forEach(row => {
        row.forEach(letter => {
          this.rawBoardLetters.push(letter)
        })
      })

      this.updateSpecialTile()
      this.phase = 1
    },

    // rollDice () {
    //   this.shuffledTiles.forEach(tile => {
    //     console.log([...tile])
    //   })
    // },

    wordExists (word) {
      if (wordlist.indexOf(word) !== -1) {
        return true
      } else {
        return false
      }
    },

    getSurroundingTiles (pos) {
      let surroundingTiles = []

      switch (pos) {
        case 0: surroundingTiles = [1, 5, 6]; break
        case 1: surroundingTiles = [0, 2, 5, 6, 7]; break
        case 2: surroundingTiles = [1, 3, 6, 7, 8]; break
        case 3: surroundingTiles = [2, 4, 7, 8, 9]; break
        case 4: surroundingTiles = [3, 8, 9]; break
        case 5: surroundingTiles = [0, 1, 6, 10, 11]; break
        case 6: surroundingTiles = [0, 1, 2, 5, 7, 10, 11, 12]; break
        case 7: surroundingTiles = [1, 2, 3, 6, 8, 11, 12, 13]; break
        case 8: surroundingTiles = [2, 3, 4, 7, 9, 12, 13, 14]; break
        case 9: surroundingTiles = [3, 4, 8, 13, 14]; break
        case 10: surroundingTiles = [5, 6, 11, 15, 16]; break
        case 11: surroundingTiles = [5, 6, 7, 10, 12, 15, 16, 17]; break
        case 12: surroundingTiles = [6, 7, 8, 11, 13, 16, 17, 18]; break
        case 13: surroundingTiles = [7, 8, 9, 12, 14, 17, 18, 19]; break
        case 14: surroundingTiles = [8, 9, 13, 18, 19]; break
        case 15: surroundingTiles = [10, 11, 16, 20, 21]; break
        case 16: surroundingTiles = [10, 11, 12, 15, 17, 20, 21, 22]; break
        case 17: surroundingTiles = [11, 12, 13, 16, 18, 21, 22, 23]; break
        case 18: surroundingTiles = [12, 13, 14, 17, 19, 22, 23, 24]; break
        case 19: surroundingTiles = [13, 14, 18, 23, 24]; break
        case 20: surroundingTiles = [15, 16, 21]; break
        case 21: surroundingTiles = [15, 16, 17, 20, 22]; break
        case 22: surroundingTiles = [16, 17, 18, 21, 23]; break
        case 23: surroundingTiles = [17, 18, 19, 22, 24]; break
        case 24: surroundingTiles = [18, 19, 23]; break
      }

      return surroundingTiles
    },

    findStringsToCheck (word, log) {
      const stringsToCheck = []

      // Define letter replacement function for wildcard
      function replaceAt (str, index, chr) {
        if (index > str.length - 1) return str
        return str.substring(0, index) + chr + str.substring(index + 1)
      }

      // Handle wildcard
      if (this.specialTile.code === '7') {
        stringsToCheck.push(word)
        for (var i = 0; i < [...word].length; i++) {
          stringsToCheck.push(replaceAt(word, i, '7'))
        }
      } else { // Handle double letter
        const splitStr = word.split(this.specialTile.value)

        if (splitStr.length === 1) { // No instances of special char found in word
          stringsToCheck.push(word)
        } else if (splitStr.length === 2) { // 1 instance of special char found in word
          stringsToCheck.push(word)
          stringsToCheck.push(`${splitStr[0]}${this.specialTile.code}${splitStr[1]}`)
        } else if (splitStr.length === 3) { // 2 instances of special char found in word
          stringsToCheck.push(word)
          stringsToCheck.push(`${splitStr[0]}${this.specialTile.code}${splitStr[1]}${this.specialTile.value}${splitStr[2]}`)
          stringsToCheck.push(`${splitStr[0]}${this.specialTile.value}${splitStr[1]}${this.specialTile.code}${splitStr[2]}`)
        } else if (splitStr.length === 4) { // 3 instances of special char found in word
          stringsToCheck.push(word)
          stringsToCheck.push(`${splitStr[0]}${this.specialTile.code}${splitStr[1]}${this.specialTile.value}${splitStr[2]}${this.specialTile.value}${splitStr[3]}`)
          stringsToCheck.push(`${splitStr[0]}${this.specialTile.value}${splitStr[1]}${this.specialTile.code}${splitStr[2]}${this.specialTile.value}${splitStr[3]}`)
          stringsToCheck.push(`${splitStr[0]}${this.specialTile.value}${splitStr[1]}${this.specialTile.value}${splitStr[2]}${this.specialTile.code}${splitStr[3]}`)
        }
      }

      if (log) { console.log(`Checking ${stringsToCheck}`) }

      return stringsToCheck
    },

    wordIsOnBoard (word, log) {
      const wordFound = []

      // Find strings to check including replacements of the double letter
      const stringsToCheck = this.findStringsToCheck(word, log)

      // Loop through all of the found strings
      stringsToCheck.forEach(word => {
        const attempts = []
        const targetWord = word

        class Attempt {
          constructor (word, path) {
            this.letterArray = [...targetWord]
            this.path = path
            this.eligibleTiles = []
          }
        }

        // Find positions on the board where the first letter exists
        const startPositions = []
        this.rawBoardLetters.forEach((letter, i) => {
          if (letter === [...targetWord][0]) {
            if (log) { console.log(`${letter} found at position ${i}`) }
            startPositions.push(i)
          }
        })

        // Create new attempts from start position
        if (startPositions.length > 0) { // First letter exists on board
          startPositions.forEach(pos => {
            attempts.push(new Attempt(targetWord, [pos]))
          })
        }

        // Start looping through all attempts

        for (var i = 0; i < attempts.length; i++) {
          if (log) { console.log(`///// Attempt #${i} /////`) }
          if (log) { console.log(attempts[i].path) }

          // Check last item in this attempt's path
          const currentTile = attempts[i].path[attempts[i].path.length - 1]
          // const currentLetter = attempts[i].letterArray[attempts[i].path.length - 1]
          const letterToSearchFor = attempts[i].letterArray[attempts[i].path.length]
          const currentEligibleTiles = new Set(this.getSurroundingTiles(currentTile)) // // Determine eligible tiles based on current letter
          let tempPath = []

          // Remove letters used in this path
          attempts[i].path.forEach(usedTile => {
            currentEligibleTiles.delete(usedTile)
          })

          // Loop through eligible tiles to look for next letter in word
          currentEligibleTiles.forEach(tileNumber => {
            if (log) { console.log(`searching for ${letterToSearchFor} at ${tileNumber} (${this.rawBoardLetters[tileNumber]})`) }
            if (letterToSearchFor === this.rawBoardLetters[tileNumber]) { // Letter matches at this tile #
              tempPath = [...attempts[i].path] // Set a new temp path with that matches path so far on thsi attempt
              tempPath.push(tileNumber) // Add found letter tile to new path
              if (log) { console.log(`Match found, new path is ${tempPath}`) }
              attempts.push(new Attempt(targetWord, tempPath)) // Create a new attempt from this
            }
          })

          if (attempts[i].path.length === targetWord.length) {
            if (log) { console.log('Completed word found on board') }
            wordFound.push(word)
          }
        }

        if (log) { console.log('End of loop reached without finding the word') }
      })

      if (log) { console.log(wordFound) }

      if (wordFound.length > 0) {
        return true
      } else {
        return false
      }
    },

    handleSubmittedWord (word) {
      // First check if the submitted word is a duplicate of an already accepted word
      const dupeFound = []
      this.submittedWords.forEach(existingWord => {
        if (word === existingWord.word) {
          dupeFound.push(word)
        }
      })

      const tempWordObj = {}

      if (dupeFound.length < 1) { // If no duplicate word is found
        tempWordObj.word = word
        tempWordObj.enabled = true

        // Assign points based on length
        if (word.length < 4) {
          tempWordObj.points = 0
        } else if (word.length === 4) {
          tempWordObj.points = 1
        } else if (word.length === 5) {
          tempWordObj.points = 2
        } else if (word.length === 6) {
          tempWordObj.points = 3
        } else if (word.length === 7) {
          tempWordObj.points = 5
        } else {
          tempWordObj.points = 11
        }

        // Check if word is on wordlist
        if (this.wordExists(word)) {
          tempWordObj.inDictionary = true

          // Check if word is on the board
          this.wordIsOnBoard(word, true) ? tempWordObj.onBoard = true : tempWordObj.onBoard = false
        } else {
          tempWordObj.inDictionary = false
        }

        // Add word object to submitted list
        this.submittedWords.unshift(tempWordObj)
      }
    },

    clearScore () {
      this.totalScore = 0
      localStorage.totalScore = 0
    },

    toggleSetting (setting) {
      if (setting === 'punishMode') { this.settings.punishMode = !this.settings.punishMode }

      localStorage.settings = JSON.stringify(this.settings)
    },

    // findAllWordsOld () {
    //   const allWords = []

    //   this.wordlist.forEach(word => {
    //     if (this.wordIsOnBoard(word, false)) {
    //       const wordObj = { word: word }
    //       allWords.push(wordObj)
    //       console.log(word)
    //     }
    //   })

    //   allWords.forEach(word => {
    //     fetch(`https://api.datamuse.com/words?sp=${word.word}&md=f&max=1`)
    //       .then(response => response.json())
    //       .then(data => {
    //         try {
    //           const f = data[0].tags[0]
    //           word.f = parseFloat(f.split('f:')[1])
    //         } catch {
    //           word.f = 0.000000001
    //         }
    //       })
    //   })

    //   setTimeout(() => {
    //     allWords.forEach(word => {
    //       console.log(`${word.word} has a frequency of ${word.f})`)
    //     })

    //     const totalWordCount = allWords.length

    //     // Sort by word length
    //     allWords.sort(function (a, b) {
    //       // ASC  -> a.length - b.length
    //       // DESC -> b.length - a.length
    //       return b.word.length - a.word.length
    //     })
    //     console.log(`Longest valid word is ${allWords[0].word} at ${allWords[0].word.length} letters`)

    //     // Sort by word rarity
    //     allWords.sort((a, b) => a.f - b.f)
    //     console.log('Sorted by frequency...')
    //     console.log(allWords)
    //     console.log(`Least common word is ${allWords[0].word} with a frequency of ${allWords[0].f} `)
    //     console.log(`Most common word is ${allWords[allWords.length - 1].word} with a frequency of ${allWords[allWords.length - 1].f}`)

    //     // Split array into 5 buckets based on rarity
    //     var chunkSize = 5
    //     var perChunk = totalWordCount / chunkSize // items per chunk
    //     var wordChunks = allWords.reduce((resultArray, item, index) => {
    //       const chunkIndex = Math.floor(index / perChunk)

    //       if (!resultArray[chunkIndex]) {
    //         resultArray[chunkIndex] = [] // start a new chunk
    //       }

    //       resultArray[chunkIndex].push(item)

    //       return resultArray
    //     }, [])

    //     console.log(wordChunks)
    //     const wordBuckets = []
    //     // var totalFrequency = 0

    //     wordChunks.forEach(bucket => {
    //       let totalFrequency = 0
    //       bucket.forEach(word => { totalFrequency += word.f })
    //       const averageFrequency = totalFrequency / bucket.length
    //       this.totalFrequency += averageFrequency

    //       const bucketObj = {
    //         words: bucket,
    //         averageFrequency: averageFrequency
    //       }

    //       wordBuckets.push(bucketObj)
    //     })

    //     console.log(wordBuckets)
    //     console.log(`Average frequency is ${this.totalFrequency / chunkSize}`)

    //     // // Determine goal
    //     // let totalFrequency = 0
    //     // const staticGoal = totalWordCount * 0.1

    //     // const weightedGoal = parseInt(staticGoal * (averageFrequency * 0.1))

    //     // console.log(`Wordcount is ${totalWordCount}, word goal is ${staticGoal}`)
    //     // console.log(`Total frequency is ${totalFrequency}, average frequency is ${averageFrequency}`)
    //     // console.log(`Weighted goal is ${weightedGoal}`)
    //   }, 10000)

    //   console.log(allWords)
    //   console.log(allWords.length)
    // },

    findAllWords () {
      const allWords = []

      this.wordlist.forEach(word => {
        if (this.wordIsOnBoard(word, false)) {
          const wordObj = { word: word }
          allWords.push(wordObj)
          console.log(word)
        }
      })

      allWords.forEach(word => {
        fetch(`https://api.datamuse.com/words?sp=${word.word}&md=f&max=1`)
          .then(response => response.json())
          .then(data => {
            try {
              const f = data[0].tags[0]
              word.f = parseFloat(f.split('f:')[1])
            } catch {
              word.f = 0.000000001
            }
          })
      })

      setTimeout(() => {
        allWords.forEach(word => {
          console.log(`${word.word} has a frequency of ${word.f})`)
        })

        const totalWordCount = allWords.length

        // Sort by word length
        allWords.sort(function (a, b) {
          // ASC  -> a.length - b.length
          // DESC -> b.length - a.length
          return b.word.length - a.word.length
        })
        console.log(`Longest word is ${allWords[0].word} at ${allWords[0].word.length} letters`)

        // Sort by word rarity
        allWords.sort((a, b) => a.f - b.f)
        console.log(`Hardest word is ${allWords[0].word} with a frequency of ${allWords[0].f} `)
        console.log(`Easiest word is ${allWords[allWords.length - 1].word} with a frequency of ${allWords[allWords.length - 1].f}`)
        console.log(allWords)

        // Sort words into buckets based on frequency and assign goals
        const easyWords = []; const hardWords = []; const impossibleWords = []
        const easyWeight = 0.35; const hardWeight = 0.1; const impossibleWeight = 0.01

        for (let i = 0; i < allWords.length; i++) {
          if (allWords[i].f <= 1) {
            impossibleWords.push(allWords[i])
          } else if (allWords[i].f <= 10) {
            hardWords.push(allWords[i])
          } else {
            easyWords.push(allWords[i])
          }
        }

        console.log(easyWords)
        console.log(hardWords)
        console.log(impossibleWords)

        const easyGoal = parseInt(easyWords.length * easyWeight)
        const hardGoal = parseInt(hardWords.length * hardWeight)
        const impossibleGoal = parseInt(impossibleWords.length * impossibleWeight)

        console.log(`total words: ${totalWordCount}`)
        console.log(`easy goal: ${easyGoal} out of ${easyWords.length}`)
        console.log(`hard goal: ${hardGoal} out of ${hardWords.length}`)
        console.log(`impossible goal: ${impossibleGoal} out of ${impossibleWords.length}`)
        console.log(`total goal: ${easyGoal + hardGoal + impossibleGoal} out of ${totalWordCount}`)

        // Score words found
        const easyWordsFound = []; const hardWordsFound = []; const impossibleWordsFound = []

        for (let i = 0; i < this.submittedWords.length; i++) {
          try {
            easyWordsFound.push(easyWords.find(o => o.word === this.submittedWords[i].word))
          } catch {
            try {
              hardWordsFound.push(hardWords.find(o => o.word === this.submittedWords[i].word))
            } catch {
              try {
                impossibleWordsFound.push(impossibleWords.find(o => o.word === this.submittedWords[i].word))
              } catch {

              }
            }
          }
        }

        console.log(easyWordsFound)

        let totalGoal = easyGoal + hardGoal + impossibleGoal
        if (totalGoal > 25) { totalGoal = 25 }

        console.log(`found ${easyWordsFound.length} out of ${easyGoal} easy words`)
        console.log(`found ${hardWordsFound.length} out of ${hardGoal} hard words`)
        console.log(`found ${impossibleWordsFound.length} out of ${impossibleGoal} impossible words`)
        console.log(`found ${easyWordsFound.length + hardWordsFound.length + impossibleWordsFound.length} out of ${totalGoal} total words`)
      }, 10000)

      console.log(allWords)
      console.log(allWords.length)
    }

  },
  mounted () {
    const self = this

    if (localStorage.totalScore) { this.totalScore = parseInt(localStorage.totalScore) }
    if (localStorage.settings) { this.settings = JSON.parse(localStorage.settings) }

    if (self.$route.query.board) { // Game loaded with board link
      console.log('Loading existing board')
      this.loadBoard(self.$route.query.board)
    } else { // Game loaded directly
      console.log('Generating new board')
      this.generateBoard()
    }

    const wordInput = this.$refs.wordInput

    wordInput.onkeydown = function (e) {
      if (e.keyCode === 13) {
        self.handleSubmittedWord(wordInput.value.toLowerCase())
        wordInput.value = ''
      }
    }
  }
}
</script>

<style lang="scss">

h1, h2, p {
    margin: 0;
}

.hidden {
  display: none;
}

#game {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#headerSection {
  margin-top: 48px;

  #logo {
    width: 300px;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  #buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 24px;

    button {
      margin: 0 4px;
    }
  }
}

#mainSection {
  margin-top: 48px;
  display: flex;
  justify-content: center;

  #boardSection {
    #board {
      background: $slate700;
      padding: 12px;
      display: inline-block;
      border-radius: 12px;
      box-shadow: 2px 2px 4px rgb(0 0 0 / 20%), 6px 6px 6px rgb(0 0 0 / 6%), 24px 24px 34px rgb(0 0 0 / 24%);

      .row {
          display: flex;
      }
    }
  }

  #sidePanel {
    width: 320px;
    margin-left: 48px;

    #gameInfo {
      display: flex;

      .infoBadge {
        padding: 12px;
        display: flex;
        background: $slate50;
        border: 2px solid $slate400;
        border-radius: $radius;
        align-items: center;

        h1 {
          font-size: 36px;
          font-weight: 600;
          color: $slate700;
          margin-left: 6px;
        }

        svg {
          path {
            stroke: $slate500;
            stroke-width: 2;
          }
        }
      }

      #timerSection {
        width: 105px;
      }

      #scoreSection {
        margin-left: 12px;
        flex-grow: 1;

        #roundScore {
          color: $slate500;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 6px;

          h2 {
            font-size: 20px;
            line-height: 16px;
          }

          p {
            font-size: 12px;
            white-space: pre;
            }
        }
      }

    }

    #inputSection {
        margin-top: 20px;

        #wordInput, button {
            width: 100%;
            height: 48px;
            font-size: 20px;
            font-weight: 700;;
        }

        #wordInput {
          background: $white;
          border: 2px solid $green500;
          border-radius: 8px;
          outline: none;
          text-align: center;
          color: $slate600;
        }
    }

    #submittedWords {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      width: 100%;
      padding: 12px 12px 12px 2px;
      box-sizing: border-box;
      border: 2px dashed $slate400;
      border-radius: 16px;
      flex-wrap: wrap;
      min-height: 100px;

      .submittedWord {
        margin: 0 0 10px 10px;
      }

    }

  }
}

#settings {
  position: absolute;
  bottom: 24px;
  left: 24px;
}
</style>
