<template>
  <div
  v-if="roundActive"
  class="submittedWord active">
    <div class="word">{{word}}</div>
  </div>

  <div
  v-else-if="((!inDictionary) && (punish))"
  class="submittedWord wordError">
    <div class="word">{{word}}</div>
    <div class="pointValue">-{{points}}</div>
  </div>

  <div
  v-else-if="((!onBoard) && (punish))"
  class="submittedWord boardError">
    <div class="word">{{word}}</div>
    <div class="pointValue">-{{points}}</div>
  </div>

  <div
  v-else-if="(!enabled)"
  class="submittedWord disabled">
    <div class="word">{{word}}</div>
    <div class="pointValue">0</div>
  </div>

  <div
  v-else
  class="submittedWord">
    <div class="word">{{word}}</div>
    <div class="pointValue">+{{points}}</div>
  </div>

</template>

<script>

export default {
  name: 'SubmittedWord',
  props: ['word', 'points', 'inDictionary', 'onBoard', 'enabled', 'punish', 'roundActive'],
  data () {
    return {
    }
  }

}
</script>

<style lang="scss" scoped>

$valid: $blue600;
$wordError: $slate700;
$boardError: $slate500;

.submittedWord {
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontstack;
    font-weight: 600;
    filter: drop-shadow(2px 2px 0px $valid);
    transition: $transition;

    &.wordError, &.boardError {
      cursor: default;
    }

    &.wordError {
      filter: drop-shadow(2px 2px 0px $wordError);
      .word, .pointValue {border-color: $wordError;}
      .word {background: $wordError;}
      .pointValue {color: $wordError;}
    }

    &.boardError {
      filter: drop-shadow(2px 2px 0px $boardError);
      .word, .pointValue {border-color: $boardError;}
      .word {background: $boardError;}
      .pointValue {color: $boardError;}
    }

    &.disabled {
      .word {background: $white; color: $valid;}
      .pointValue {font-weight: 500;}
    }

    &.active {
      filter: none;

      .word {
        border-radius: $radius;
        background: $white;
        color: $slate700;
        border-color: $slate700;
      }
    }

  .word, .pointValue {
    display: flex;
    align-items: center;
    background: $white;
    padding: 6px;
    border-width: 2px;
    border-style: solid;
    border-color: $valid;
    transition: $transition;
  }

  .word {
    border-radius: $radius 0 0 $radius;
    background: $valid;
    color: $white;
    font-weight: 500;
  }

  .pointValue {
    border-radius: 0 $radius $radius 0;
    border-width: 2px 2px 2px 0px;
    background: $white;
    color: $valid;
    font-weight: 700;
  }
}

</style>
