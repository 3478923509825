<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Borgle',
    // titleTemplate: '%s - Test',
    htmlAttrs: {
      lang: 'en',
      amp: true
    },
    meta: [
      { name: 'description', content: 'XXXXXXXX' },
      { property: 'og:title', content: 'XXXXXXXXXX' },
      { property: 'og:site_name', content: 'Borgle' },
      { property: 'og:type', content: 'website' },
      { name: 'robots', content: 'index,follow' }
    ]
  }
}
</script>

<style lang="scss">

body {
  background: $slate100;
}

* {
  font-family: $fontstack;
  box-sizing: border-box;
}

button {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 14px;
  border: none;
  cursor: pointer;
  outline: none;
  border-radius: 8px;

  &.primary {
    background: $emerald500;
    color: $white;
  }

  &.secondary {
    background: $slate700;
    color: $white;
  }

  &.tertiary {
    border: 2px solid $slate500;
    background: transparent;
    color: $slate500;
  }

  &:disabled {
    cursor: default;
    background: $slate300;
    color: $slate500;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
