<template>
    <div class="tile" v-bind:class="{'hidden':hideLetters}">

        <div
        v-if="!hideLetters"
        class="letter">
            <span v-if="letter==='1'">Q<span class="lower">u</span></span>
            <span v-else-if="letter==='2'">I<span class="lower">n</span></span>
            <span v-else-if="letter==='3'">T<span class="lower">h</span></span>
            <span v-else-if="letter==='4'">E<span class="lower">r</span></span>
            <span v-else-if="letter==='5'">H<span class="lower">e</span></span>
            <span v-else-if="letter==='6'">A<span class="lower">n</span></span>
            <span v-else-if="letter==='7'">█</span>
            <span v-else>{{letter}}</span>
        </div>

        <span v-if="debug" class="tileIndex">{{tileIndex}}</span>
    </div>

</template>

<script>

export default {
  name: 'Tile',
  props: ['letter', 'tileIndex', 'debug', 'hideLetters'],
  data () {
    return {
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

    $size : 72px;

    .tile {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 4px;
        background: rgb(255,255,255);
        background: radial-gradient(circle, white 60%, #ced6e3 80%);
        width: $size;
        height: $size;
        border-radius: 10px;
        box-shadow: 7px 5px 0px 0px #00000040;

        font-weight: 800;
        font-size: $size/2;
        text-transform: uppercase;
        color: $slate700;

        .tileIndex {
            font-size: 12px;
            position: relative;
            top: -20px;
            right: -10px;
        }

        .lower {
            text-transform: lowercase;
        }
    }

    .hideLetters { }
</style>
